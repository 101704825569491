export class GlobalLists {

    public static stateList: string[] = [null, 'AL', 'AK', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];

    public static localOnlyStatesAgent: string[] = ['AK', 'AZ', 'CO', 'IL', 'IN', 'MD', 'MO', 'OR'];
    public static localOnlyStatesAgency: string[] = ['AK', 'AZ', 'CO', 'DE', 'FL', 'GA', 'IL', 'IN', 'IA', 'MD', 'MN', 'MS', 'MO', 'NC', 'NE', 'NM', 'OR', 'RI', 'SC', 'TN', 'VT', 'WV', 'WI'];


    public static floridaCountyList: {
        code: number; name: string;
    } [] = [{ code: 1, name: "Dade-Miami" },
    { code: 2, name: "Duval-Jacksonville" },
    { code: 3, name: "Hillsborough-Tampa" },
    { code: 4, name: "Pinellas-Clearwater" },
    { code: 5, name: "Polk-Bartow" },
    { code: 6, name: "Palm Beach-West Palm Beach" },
    { code: 7, name: "Orange-Orlando" },
    { code: 8, name: "Volusia-De Land" },
    { code: 9, name: "Escambia-Pensacola" },
    { code: 10, name: "Broward-Fort Lauderdale" },
    { code: 11, name: "Alachua-Gainsville" },
    { code: 12, name: "Lake-Tavares" },
    { code: 13, name: "Leon-Tallahassee" },
    { code: 14, name: "Marion-Ocala" },
    { code: 15, name: "Manatee-Bradenton" },
    { code: 16, name: "Sarasota-Sarasota" },
    { code: 17, name: "Seminole-Sanford" },
    { code: 18, name: "Lee-Fort Myers" },
    { code: 19, name: "Brevard-Titusville" },
    { code: 20, name: "St. Johns-St. Augustine" },
    { code: 21, name: "Gadsden-Quincy" },
    { code: 22, name: "Putnam-Palatka" },
    { code: 23, name: "Bay-Panama City" },
    { code: 24, name: "St. Lucie-Fort Pierce" },
    { code: 25, name: "Jackson-Marianna" },
    { code: 26, name: "Osceola-Kissimmee" },
    { code: 27, name: "Highlands-Sebring" },
    { code: 28, name: "Pasco-Dade City" },
    { code: 29, name: "Columbia-Lake City" },
    { code: 30, name: "Hardee-Wauchula" },
    { code: 31, name: "Suwannee-Live Oak" },
    { code: 32, name: "Indian River-Vero Beach" },
    { code: 33, name: "Santa Rosa-Milton" },
    { code: 34, name: "De Soto-Arcadia" },
    { code: 35, name: "Madison-Madison" },
    { code: 36, name: "Walton-De Funiak Springs" },
    { code: 37, name: "Taylor-Perry" },
    { code: 38, name: "Monroe-Key West" },
    { code: 39, name: "Levy-Bronson" },
    { code: 40, name: "Hernando-Brooksville" },
    { code: 41, name: "Nassau" },
    { code: 42, name: "Martin" },
    { code: 43, name: "Okaloosa" },
    { code: 44, name: "Sumter" },
    { code: 45, name: "Bradford" },
    { code: 46, name: "Jefferson" },
    { code: 47, name: "Citrus" },
    { code: 48, name: "Clay" },
    { code: 49, name: "Hendry" },
    { code: 50, name: "Washington" },
    { code: 51, name: "Holmes" },
    { code: 52, name: "Baker" },
    { code: 53, name: "Charlotte" },
    { code: 54, name: "Dixie" },
    { code: 55, name: "Gilchrist" },
    { code: 56, name: "Hamilton" },
    { code: 57, name: "Okeechobee" },
    { code: 58, name: "Calhoun" },
    { code: 59, name: "Franklin" },
    { code: 60, name: "Glades" },
    { code: 61, name: "Flagler" },
    { code: 62, name: "Lafayette" },
    { code: 63, name: "Union" },
    { code: 64, name: "Collier" },
    { code: 65, name: "Wakulla" },
    { code: 66, name: "Gulf" },
    { code: 67, name: "Liberty" }];

}
